<template>
  <v-select
    dense
    :disabled="disabled"
    :label="label"
    item-text="label"
    :items="items"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change')"
    :tabindex="tabindex"
    hide-details
  />
</template>
<script>
export default {
  props: ["value", "label", "items", "disabled", "tabindex"],
};
</script>
