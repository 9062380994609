<template>
  <v-text-field
    :disabled="disabled"
    :label="label"
    :value="value"
    @input="$emit('input', $event)"
    hide-details
  />
</template>
<script>
export default {
  props: ["value", "label", "items", "disabled"],
};
</script>
