var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-combobox',{attrs:{"disabled":_vm.disabled,"dense":_vm.dense,"label":_vm.label,"value":_vm.value,"items":_vm.items,"hide-details":_vm.hideDetail,"solo":_vm.solo,"flat":_vm.flat,"item-text":_vm.itemText,"tabindex":_vm.tabindex,"filter":_vm.searchClientFilter,"return-object":""},on:{"input":function($event){return _vm.$emit('input', $event)},"keyup":function($event){$event.preventDefault();return _vm.$emit('keyup', $event)},"change":function($event){return _vm.$emit('change', $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(
        _vm.items.filter(function (x) { return x.name === item.name && x.status !== '99'; })
          .length >= 2
      )?[_c('v-menu',{attrs:{"offset-x":"","transition":"slide-y-transition","max-width":"400px","nudge-width":400,"nudge-top":"10","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-list-item-title',_vm._g({},on),[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(_vm._s(item.name)+" "+_vm._s(item.bill_client ? (" - " + (item.bill_client)) : "")+" ")])]}}],null,true)},[_c('span',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',_vm._l((_vm.items.filter(
                  function (x) { return x.name === item.name && x.status !== '99'; }
                )),function(dupItem){return _c('v-list-item',{key:dupItem._id,on:{"click":function($event){return _vm.dupleclientChange(dupItem)}}},[_c('v-tooltip',{attrs:{"color":"primary","dark":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-list-item-content',_vm._g(_vm._b({},'v-list-item-content',attrs,false),on),[_vm._v(" "+_vm._s(dupItem.business_num ? ((dupItem.name) + " [" + (_vm.getBusinessNumMask( dupItem.business_num )) + "] ") : dupItem.name)+" ")])]}}],null,true)},[_c('div',{staticClass:"tooltipText"},[_c('span',[_vm._v(" 거래처명 : "+_vm._s(dupItem.name)+" ")]),_c('span',[_vm._v(" 대표명 : "+_vm._s(dupItem.officer)+" ")]),_c('span',[_vm._v(" 사업자번호 : "+_vm._s(_vm.getBusinessNumMask(dupItem.business_num))+" ")]),_c('span',[_vm._v(" 계산서 발행처 : "+_vm._s(dupItem.bill_client ? dupItem.bill_client.name : "")+" ")]),_c('span',[_vm._v(" 주소 : "+_vm._s(dupItem.address)+" ")]),_c('span',[_vm._v(" 전화번호 : "+_vm._s(dupItem.tel)+" ")]),_c('span',[_vm._v(" 연락담당자 : "+_vm._s(dupItem.liaison)+" ")])])])],1)}),1)],1),_vm._t("default")],2)])]:[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.bill_client ? (" - " + (item.bill_client.name)) : "")+" ")]]}},{key:"prepend-inner",fn:function(){return [_c('v-btn',{staticClass:"ma-0 pa-0",attrs:{"icon":"","x-small":"","disabled":(_vm.disabled || typeof _vm.value) === 'object' ? false : true},on:{"click":function($event){return _vm.$emit('click', _vm.value)}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v(" mdi-pencil")])],1)]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }