<template>
  <v-combobox
    :disabled="disabled"
    :dense="dense"
    :label="label"
    :value="value"
    :items="items"
    :hide-details="hideDetail"
    :solo="solo"
    :flat="flat"
    @input="$emit('input', $event)"
    @keyup.prevent="$emit('keyup', $event)"
    @change="$emit('change', $event)"
    :item-text="itemText"
    :tabindex="tabindex"
    :filter="searchClientFilter"
    return-object
  >
    <template v-slot:item="{ item }">
      <template
        v-if="
          items.filter((x) => x.name === item.name && x.status !== '99')
            .length >= 2
        "
      >
        <v-menu
          offset-x
          transition="slide-y-transition"
          max-width="400px"
          :nudge-width="400"
          nudge-top="10"
          open-on-hover
        >
          <template v-slot:activator="{ on }">
            <v-list-item-title v-on="on">
              <span class="red--text">*</span>{{ item.name }}
              {{ item.bill_client ? ` - ${item.bill_client}` : "" }}
            </v-list-item-title>
          </template>

          <span>
            <v-list dense>
              <v-list-item-group>
                <v-list-item
                  v-for="dupItem in items.filter(
                    (x) => x.name === item.name && x.status !== '99',
                  )"
                  :key="dupItem._id"
                  @click="dupleclientChange(dupItem)"
                >
                  <v-tooltip color="primary" dark right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-content v-on="on" v-bind="attrs">
                        {{
                          dupItem.business_num
                            ? `${dupItem.name} [${getBusinessNumMask(
                                dupItem.business_num,
                              )}] `
                            : dupItem.name
                        }}
                      </v-list-item-content>
                    </template>
                    <div class="tooltipText">
                      <span> 거래처명 : {{ dupItem.name }} </span>
                      <span>
                        대표명 :
                        {{ dupItem.officer }}
                      </span>
                      <span>
                        사업자번호 :
                        {{ getBusinessNumMask(dupItem.business_num) }}
                      </span>
                      <span>
                        계산서 발행처 :
                        {{
                          dupItem.bill_client ? dupItem.bill_client.name : ""
                        }}
                      </span>
                      <span> 주소 : {{ dupItem.address }} </span>
                      <span> 전화번호 : {{ dupItem.tel }} </span>
                      <span> 연락담당자 : {{ dupItem.liaison }} </span>
                    </div>
                  </v-tooltip>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <slot />
          </span>
        </v-menu>
      </template>
      <template v-else>
        {{ item.name }}
        {{ item.bill_client ? ` - ${item.bill_client.name}` : "" }}
      </template>
    </template>

    <template v-slot:prepend-inner>
      <v-btn
        icon
        x-small
        class="ma-0 pa-0"
        v-on:click.native.stop=""
        @click="$emit('click', value)"
        :disabled="(disabled || typeof value) === 'object' ? false : true"
      >
        <v-icon> mdi-pencil</v-icon>
      </v-btn>
    </template>
  </v-combobox>
</template>

<script>
export default {
  props: [
    "value",
    "label",
    "items",
    "disabled",
    "itemText",
    "tabindex",
    "change",
    "dense",
    "hideDetail",
    "solo",
    "flat",
    "filter",
  ],
  methods: {
    dupleclientChange(item) {
      this.$children[0].selectItem(item);
      this.$children[0].isActive = false;
    },
    searchClientFilter(item, queryText) {
      const searchTarget = `${item.name} ${
        item.bill_client ? ` - ${item.bill_client.name}` : ""
      }  `;

      return (
        searchTarget
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        searchTarget
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
  },
};
</script>
