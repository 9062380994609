<template>
  <v-row>
    <v-col cols="4">
      <v-text-field
        v-model="editedItem.name"
        label="이름*"
        :disabled="$store.state.editedItem._id ? true : false"
      ></v-text-field>
    </v-col>
    <v-col cols="3">
      <v-text-field
        v-model="editedItem.unit_price"
        label="가격"
        type="number"
      ></v-text-field>
    </v-col>
    <v-col cols="2">
      <v-select
        label="단위 (가격)"
        item-text="label"
        v-model="editedItem.currency_sign"
        :items="$store.state.currency_sign"
      ></v-select>
    </v-col>
    <v-col cols="2">
      <v-select
        label="단위 (길이)"
        v-model="editedItem.unit_length"
        :items="$store.state.unit_sign"
        item-text="label"
      ></v-select>
    </v-col>
    <v-col cols="1" class="pl-0">
      <span class="mb-0 textNowrap">{{ inspectStatusText }}</span>
      <v-checkbox
        v-model="editedItem.inspect_status"
        item-text="label"
        hide-details
        :ripple="false"
        class="ml-3"
        @change="StatusCheck()"
      ></v-checkbox>
    </v-col>
    <v-col cols="6">
      <v-combobox
        label="생산처*"
        v-model="editedItem.production"
        :items="clientList"
        item-text="name"
        return-object
      >
        <!-- <template v-slot:item="{ item }">
          {{ item.sensibleName }}
        </template>
        <template v-slot:selection="{ item }">
          {{ item.name }}
        </template> -->
      </v-combobox>
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="editedItem.production_item"
        label="생산처 Item No*"
      >
      </v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field v-model="editedItem.slope" label="경사"></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field v-model="editedItem.oblique" label="위사"></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field v-model="editedItem.variety" label="혼용률"></v-text-field>
    </v-col>
    <v-col cols="3">
      <v-text-field
        v-model="editedItem.width"
        label="가용 폭"
        type="number"
        @change="itemWidthCheck($event)"
      ></v-text-field>
    </v-col>
    <v-col cols="3">
      <v-text-field
        v-model="editedItem.full_width"
        label="전체 폭"
        type="number"
        @change="itemWidthCheck($event)"
      ></v-text-field>
    </v-col>
    <v-col cols="2">
      <v-select
        label="단위 (폭 길이)"
        v-model="editedItem.unit_width"
        :items="$store.state.unit_width"
        item-text="label"
      ></v-select>
    </v-col>
    <v-col cols="5">
      <v-text-field v-model="editedItem.density" label="밀도"></v-text-field>
    </v-col>
    <v-col cols="5">
      <v-text-field
        v-model="editedItem.weight"
        label="중량"
        type="number"
      ></v-text-field>
    </v-col>
    <v-col cols="2">
      <v-select
        label="단위 (무게)"
        v-model="editedItem.unit_weight"
        :items="$store.state.unit_weight"
        item-text="label"
      ></v-select>
    </v-col>
    <v-col cols="12">
      <v-text-field
        v-model="editedItem.post_processing"
        label="Finish"
      ></v-text-field>
    </v-col>
    <v-col cols="10">
      <v-text-field
        v-model="editedItem.content"
        label="특이사항"
      ></v-text-field>
    </v-col>
    <v-col cols="2">
      <v-text-field v-model="editedItem.liaison" label="담당자"></v-text-field>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  computed: {
    ...mapGetters({
      editedItem: "getEditedItem",
      clientList: "getUseClientList",
    }),
  },
  data() {
    return {
      inspectStatusText: "검사포함",
    };
  },
  methods: {
    itemWidthCheck() {
      if (
        this.editedItem.width !== "" &&
        this.editedItem.full_width !== "" &&
        +this.editedItem.width > +this.editedItem.full_width
      ) {
        this.$store.commit(
          "setSnackBar",
          `가용 폭은 전체 폭을 초과할 수 없습니다.`,
        );
        this.editedItem.width = "";
      }
    },
    StatusCheck() {
      this.editedItem.inspect_status
        ? (this.inspectStatusText = "검사포함")
        : (this.inspectStatusText = "검사미포함");
    },
  },
};
</script>
