<template>
  <article>
    <div class="commonTableRowCell" style="width: 100%">
      <div class="commonTableHeader" style="width: 100%">
        <div
          class="text-center commonTableCell"
          v-for="col in styleHeader"
          :key="col.value"
          :style="{ width: col.width }"
        >
          <span>{{ col.text }} </span>
        </div>
      </div>
    </div>
    <div class="commonTableRowCell" style="width: 100%">
      <div class="tableTotalCell" style="width: 100%">
        <div
          class="text-center commonTableCell"
          v-for="col in styleHeader"
          :key="col.value"
          :style="{ width: col.width }"
        >
          <span v-if="col.value === 'style'"> TOTAL </span>
          <span v-if="col.value === 'count'">
            {{ order.style_list ? order.style_list.length + "개" : "0개" }}
          </span>
        </div>
      </div>
    </div>
    <div class="commonTableRowCell" style="width: 100%">
      <div class="tableRowNoneBorder" style="width: 100%">
        <div class="tableColNoneBorder" style="width: 30%">
          <v-text-field
            :disabled="form_disable"
            v-model="styleItem"
            @keypress.enter="styleItemsPlus()"
            ref="`inputTab12`"
            tabindex="12"
            style="width: 80%"
            dense
            hide-details
          ></v-text-field>
        </div>
        <div
          class="tableColNoneBorder pr-4"
          style="width: 10%; transform: translateY(5px)"
        >
          <v-icon :disabled="form_disable" medium @click="styleItemsPlus()">
            mdi-plus
          </v-icon>
        </div>
      </div>
    </div>
    <div class="commonTableRowCell" style="width: 100%">
      <overlay-scrollbars
        :options="{
          overflowBehavior: {
            x: 'hidden',
          },
        }"
      >
        <div
          class="commonFlexTableBody"
          style="width: 100%; max-height: calc(40vh - 132px)"
        >
          <template v-if="!order.style_list.length">
            <span class="d-flex justify-center pt-4 unitPriceComma">
              스타일이 존재하지 않습니다.
            </span>
          </template>
          <div class="commonTableRow">
            <div
              class="text-center commonTableCell"
              v-for="(col, i) in styleHeader"
              :key="i"
              :style="{ width: col.width }"
            >
              <div
                v-for="(item, index) in order.style_list"
                :key="item"
                class="tableBodyCell"
                style="border-bottom: thin solid rgba(0, 0, 0, 0.12)"
              >
                <span v-if="editIndex !== index && col.value === 'style'">
                  {{ item }}
                </span>
                <span v-if="editIndex === index && col.value === 'style'">
                  <v-text-field
                    hide-details
                    dense
                    :value="item"
                    @input="inputStyle($event)"
                  >
                  </v-text-field>
                </span>
                <span v-if="col.value === 'count'">
                  <v-icon
                    color="green"
                    small
                    @click="editStyle(index)"
                    :disabled="form_disable"
                    v-if="editIndex === index"
                  >
                    mdi-check
                  </v-icon>
                  <v-icon
                    small
                    @click="modifyStyle(index)"
                    :disabled="form_disable"
                    v-else
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon v-if="form_disable" small disabled>
                    mdi-delete
                  </v-icon>
                  <v-icon v-else small class="" @click="styleItemDelete(index)">
                    mdi-delete
                  </v-icon>
                </span>
              </div>
            </div>
          </div>
        </div>
      </overlay-scrollbars>
    </div>
  </article>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      maxHeight: 0,
      styleItem: "",
      editIndex: null,
      editInputStyle: "",
      styleHeader: [
        {
          text: "스타일",
          value: "style",
          align: "center",
          width: "50%",
        },
        {
          text: "TOTAL",
          value: "count",
          align: "center",
          width: "40%",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      order: "getOrderForm",
    }),
    heightTable() {
      return `${this.windowHeight - 800}px`;
    },
    form_disable() {
      return (
        ["10", "20", "00", "50"].includes(this.order.status) ||
        this.order.status_modify === false
      );
    },
  },
  methods: {
    styleItemsPlus() {
      if (this.styleItem !== "") {
        let styleOverlapCheck = false;

        this.order.style_list.forEach((x) => {
          if (x === this.styleItem) styleOverlapCheck = true;
        });

        if (!styleOverlapCheck) {
          const Style = {};

          this.order.style_list.push(this.styleItem);

          this.styleItem = "";
        } else {
          this.$store.commit("setSnackBar", `이미 입력된 STYLE NO 입니다.`);
        }
      } else {
        this.$store.commit("setSnackBar", `STYLE을 추가해주세요.`);
      }
    },
    styleItemDelete(index) {
      this.order.style_list.splice(index, 1);
      this.$store.commit("setSnackBar", `Delete Complete : STYLE`);
    },
    modifyStyle(index) {
      this.editIndex = index;
    },
    inputStyle(event) {
      this.editInputStyle = event;
    },
    editStyle(index) {
      if (this.order.color_list)
        // 컬러리스트에서 수정한 스타일과 같은 스타일이있으면 전부 변경
        this.order.color_list = this.order.color_list.map((data) =>
          data.style === this.order.style_list[index]
            ? { ...data, style: this.editInputStyle }
            : { ...data },
        );
      this.order.style_list[index] = this.editInputStyle;

      this.editInputStyle = "";
      this.editIndex = null;
    },
    updateHeight() {
      this.maxHeight = (window.innerHeight - 186) / 2 - 132; // 전체 위도우 사이즈 - 헤더,footer 높이 / grid 갯수 - 테이블 헤더높이
    },
  },
  mounted() {
    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateHeight);
  },
};
</script>

<style></style>
