<template>
  <v-btn
    @click="$emit('click')"
    :disabled="disabled"
    class="elevation-0 font-weight-black topGrayButton"
    height="30"
    color="grey lighten-2 "
    :width="width"
  >
    <slot />
  </v-btn>
</template>
<script>
export default {
  props: ["disabled", "width"],
};
</script>
