<template>
  <v-date-picker
    :value="value"
    no-title
    scrollable
    locale="kr"
    @input="$emit('input', $event)"
  >
  </v-date-picker>
</template>
<script>
export default {
  props: ["value", "input"],
};
</script>
