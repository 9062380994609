<template>
  <v-select
    :disabled="disabled"
    :label="label"
    item-text="label"
    :items="items"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change')"
  />
</template>
<script>
export default {
  props: ["value", "label", "items", "disabled"],
};
</script>
