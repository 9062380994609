<template>
  <article>
    <div class="commonTableRowCell colorBorderTop" style="width: 100%">
      <div class="commonTableHeader" style="width: 100%">
        <div
          class="text-center commonTableCell"
          v-for="col in colorHeader"
          :key="col.value"
          :style="{ width: col.width }"
        >
          <span v-if="col.value === 'unitSign'">
            <DenseLabelSelect
              :disabled="form_disable"
              label="길이 단위"
              v-model="order.unit_sign"
              :items="$store.state.unit_sign"
              class="px-3"
            />
          </span>
          <span v-else>{{ col.text }} </span>
        </div>
      </div>
    </div>
    <div class="commonTableRowCell" style="width: 100%">
      <div class="tableTotalCell" style="width: 100%">
        <div
          class="text-center commonTableCell"
          v-for="col in colorHeader"
          :key="col.value"
          :style="{ width: col.width }"
        >
          <div style="transform: translateX(40px)">
            <span v-if="col.value === 'style'"> TOTAL </span>
          </div>
          <span v-if="col.value === 'quantity'">
            {{ ((colorTotal ? colorTotal : 0) + order.unit_sign) | makeComma }}
          </span>
          <span v-if="col.value === 'surcharge_price'">
            {{
              (order.eschamge_sign + (surchargeTotal ? surchargeTotal : 0))
                | makeComma
            }}
          </span>
          <span v-if="col.value === 'unit_price'">
            {{
              (order.eschamge_sign + (unitPriceTotal ? unitPriceTotal : 0))
                | makeComma
            }}
          </span>
          <span
            v-if="col.value === 'unitSign'"
            style="width: 57.7px"
            class="d-flex"
          >
          </span>
        </div>
      </div>
    </div>
    <div class="commonTableRowCell">
      <div class="tableRowNoneBorder">
        <div class="tableColNoneBorder" style="width: 20%">
          <span>
            <v-autocomplete
              :disabled="form_disable"
              v-model="colorEditedItem.style"
              dense
              :items="order.style_list"
              class="colorStyle"
              hide-details
              flat
              no-data-text="스타일이 존재하지 않습니다."
              style="margin-top: 0px !important"
            >
            </v-autocomplete>
          </span>
        </div>
        <div class="tableColNoneBorder" style="width: 25%">
          <v-text-field
            :disabled="form_disable"
            v-model="colorEditedItem.name"
            type="text"
            @keypress.enter="colorItemPlus()"
            :tabindex="13"
            class="pl-1 pr-1 pt-0 pb-2"
            dense
            hide-details
            flat
          ></v-text-field>
        </div>
        <div class="tableColNoneBorder" style="width: 15%">
          <v-text-field
            :disabled="form_disable"
            v-model="colorEditedItem.quantity"
            @keypress.enter="colorItemPlus()"
            :tabindex="14"
            @input="(e) => (colorEditedItem.quantity = usingComma(e))"
            class="pl-1 pr-1 pt-0 pb-2"
            dense
            flat
            hide-details
          ></v-text-field>
        </div>
        <div class="tableColNoneBorder" style="width: 20%">
          <v-text-field
            :disabled="form_disable"
            v-model="colorEditedItem.unit_price"
            @keypress.enter="colorItemPlus()"
            :tabindex="14"
            class="pl-1 pr-1 pt-0 pb-2"
            @input="(e) => (colorEditedItem.unit_price = usingComma(e))"
            dense
            flat
            hide-details
          ></v-text-field>
        </div>
        <div class="tableColNoneBorder" style="width: 26%">
          <v-text-field
            :disabled="form_disable"
            v-model="colorEditedItem.surcharge_price"
            @keypress.enter="colorItemPlus()"
            :tabindex="14"
            class="pl-1 pr-1 pt-0 pb-2"
            @input="(e) => (colorEditedItem.surcharge_price = usingComma(e))"
            dense
            flat
            hide-details
          ></v-text-field>
        </div>

        <div
          class="tableColNoneBorder"
          style="
            width: 57.7px;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateY(10px);
          "
        >
          <v-icon :disabled="form_disable" medium @click="colorItemPlus()">
            mdi-plus
          </v-icon>
        </div>
      </div>
    </div>
    <div class="commonTableRowCell" style="width: 100%">
      <overlay-scrollbars
        :options="{
          overflowBehavior: {
            x: 'hidden',
          },
        }"
      >
        <div
          class="commonFlexTableBody"
          style="width: 100%; max-height: calc(40vh - 132px)"
        >
          <template v-if="!order.color_list.length">
            <span class="d-flex justify-center pt-4 unitPriceComma">
              컬러가 존재하지 않습니다.
            </span>
          </template>
          <div class="commonTableRow">
            <div
              class="text-center commonTableCell"
              v-for="(col, i) in colorHeader"
              :key="i"
              :style="{ width: col.width }"
            >
              <div
                v-for="(item, index) in order.color_list"
                :key="item.name"
                class="tableBodyCell"
                style="
                  width: 100%;
                  border-bottom: thin solid rgba(0, 0, 0, 0.12);
                "
              >
                <template v-if="editIndex !== index">
                  <span v-if="col.value === 'style'">
                    {{ item.style ? item.style : "" }}
                  </span>
                  <span v-if="col.value === 'name'">
                    {{ item.name ? item.name : "" }}
                  </span>
                  <span v-if="col.value === 'quantity'">
                    {{
                      `${
                        item.quantity
                          ? `${item.quantity}${order.unit_sign}`
                          : ""
                      } ` | makeComma
                    }}
                  </span>
                  <span v-if="col.value === 'unit_price'">
                    {{
                      `${
                        item.unit_price
                          ? order.eschamge_sign +
                            (item.unit_price ? item.unit_price : "")
                          : ""
                      } ` | makeComma
                    }}
                  </span>
                  <span v-if="col.value === 'surcharge_price'">
                    {{
                      `${
                        item.surcharge_price
                          ? order.eschamge_sign +
                            (item.surcharge_price ? item.surcharge_price : "")
                          : ""
                      } ` | makeComma
                    }}
                  </span>
                </template>
                <template v-if="editIndex === index">
                  <div v-if="col.value === 'style'">
                    <v-autocomplete
                      :disabled="form_disable"
                      v-model="item.style"
                      dense
                      :items="order.style_list"
                      class="colorStyle"
                      hide-details
                      flat
                      no-data-text="스타일이 존재하지 않습니다."
                      style="margin-top: 0px !important"
                    >
                    </v-autocomplete>
                  </div>
                  <div v-if="col.value === 'name'">
                    <v-text-field hide-details dense v-model="item.name">
                    </v-text-field>
                  </div>
                  <div v-if="col.value === 'quantity'">
                    <v-text-field hide-details dense v-model="item.quantity">
                    </v-text-field>
                  </div>
                  <div v-if="col.value === 'unit_price'">
                    <v-text-field hide-details dense v-model="item.unit_price">
                    </v-text-field>
                  </div>
                  <div v-if="col.value === 'surcharge_price'">
                    <v-text-field
                      hide-details
                      dense
                      v-model="item.surcharge_price"
                    >
                    </v-text-field>
                  </div>
                </template>
                <span
                  v-if="col.value === 'unitSign'"
                  style="
                    width: 57.7px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <v-icon
                    color="green"
                    small
                    @click="editColor(index)"
                    :disabled="form_disable"
                    v-if="editIndex === index"
                  >
                    mdi-check
                  </v-icon>
                  <v-icon
                    small
                    @click="modifyColor(index)"
                    :disabled="form_disable"
                    v-else
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteColor(index)"
                    :disabled="form_disable"
                  >
                    mdi-delete
                  </v-icon>
                </span>
              </div>
            </div>
          </div>
        </div>
      </overlay-scrollbars>
    </div>
  </article>
</template>

<script>
import DenseLabelSelect from "@/components/atoms/VSelect/DenseLabelSelect";
import { mapGetters } from "vuex";
export default {
  components: {
    DenseLabelSelect,
  },
  data() {
    return {
      maxHeight: 0,
      colorEditedItem: {},
      colorItems: [],
      editIndex: null,

      colorHeader: [
        {
          text: "스타일",
          value: "style",
          align: "center",
          width: "20%",
        },
        {
          text: "컬러",
          value: "name",
          align: "center",
          width: "25%",
        },
        {
          text: "수량",
          value: "quantity",
          align: "center",
          width: "15%",
        },
        {
          text: "단가(총액)",
          value: "unit_price",
          align: "center",
          width: "26%",
        },
        {
          text: "Surcharge",
          value: "surcharge_price",
          align: "center",
          width: "26%",
        },
        {
          text: "",
          value: "unitSign",
          align: "center",
          width: "30%",
        },
      ],
      snackbarData: {},
    };
  },
  computed: {
    ...mapGetters({
      order: "getOrderForm",
    }),
    heightTable() {
      return `${this.windowHeight - 800}px`;
    },
    form_disable() {
      return (
        ["10", "20", "00", "50"].includes(this.order.status) ||
        this.order.status_modify === false
      );
    },
    colorTotal() {
      return this.roundsData(
        this.order.color_list.reduce(
          (r, i) => (i.quantity ? r + +i.quantity : r),
          0,
        ),
      );
    },
    unitPriceTotal() {
      const colorPrice = this.order.color_list.reduce(
        (r, i) => (i.unit_price ? r + i.unit_price * i.quantity : r),
        0,
      );
      return this.roundsData(colorPrice + this.surchargeTotal);
    },
    surchargeTotal() {
      return this.roundsData(
        this.order.color_list.reduce(
          (r, i) => (i.surcharge_price ? r + +i.surcharge_price : r),
          0,
        ),
      );
    },
  },
  methods: {
    selectColorName(id) {
      let data = {};
      this.colorList.forEach((x) => {
        if (x._id === id) data = x;
      });
      return data;
    },
    colorInputCheck() {
      if (!this.colorEditedItem.name) {
        this.$store.commit("setSnackBar", `컬러를 입력해주세요.`);

        return false;
      } else if (!this.colorEditedItem.quantity) {
        this.$store.commit("setSnackBar", `수량을 입력해주세요.`);

        return false;
      }
      return true;
    },
    colorItemPlus() {
      let checkColorOverlap = false;
      if (this.colorInputCheck()) {
        this.order.color_list.forEach((x) => {
          if (x.name.toLowerCase() === this.colorEditedItem.name.toLowerCase())
            checkColorOverlap = true;
        });

        if (!checkColorOverlap) {
          const color = {
            style: this.colorEditedItem.style,
            name: this.colorEditedItem.name,
            quantity: this.colorEditedItem.quantity,
            unit_price: this.colorEditedItem.unit_price,
            surcharge_price: this.colorEditedItem.surcharge_price,
          };

          // this.colorItems.push(color);
          this.order.color_list.push(color);

          this.order.color_list.quantity = this.order.color_list.reduce(
            (r, i) => (i.quantity ? r + +i.quantity : r),
            0,
          );
          this.colorEditedItem = {};
        } else {
          this.$store.commit("setSnackBar", `중복된 컬러가 있습니다.`);
          this.snackbar = true;
        }
      }
    },
    deleteColor(index) {
      this.order.color_list.splice(index, 1);
      this.colorItems = [];
      this.order.color_list.quantity = this.order.color_list.reduce(
        (r, i) => (i.quantity ? r + +i.quantity : r),
        0,
      );
      this.$store.commit("setSnackBar", `Delete Complete : Color`);
    },
    modifyColor(index) {
      this.editIndex = index;
    },
    editColor(index) {
      this.editIndex = null;
    },
    updateHeight() {
      this.maxHeight = (window.innerHeight - 186) / 2 - 132; // 전체 위도우 사이즈 - 헤더,footer 높이 / grid 갯수 - 테이블 헤더높이
    },
  },

  mounted() {
    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateHeight);
  },
};
</script>

<style scoped></style>
