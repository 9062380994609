<template>
  <v-dialog
    max-width="600px"
    :value="isConfirmDialog"
    @click:outside="$store.state.isDialog = false"
    @keydown.esc="$store.state.isDialog = false"
    @keydown.enter="callSave"
  >
    <v-card>
      <v-card-title>
        <span> {{ $store.state.dialogTitle }} </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <template v-if="this.$route.name === 'order'">
            <v-row>
              <v-col cols="4">
                <TextField
                  disabled
                  :value="
                    $store.state.orderForm.order
                      ? $store.state.orderForm.order
                      : ''
                  "
                  label="Order"
                />
              </v-col>

              <v-col cols="3">
                <TextField
                  disabled
                  :value="
                    $store.state.orderForm.client
                      ? $store.state.orderForm.client.name
                      : ''
                  "
                  label="업체명"
                />
              </v-col>
              <v-col cols="3">
                <TextField
                  disabled
                  :value="
                    $store.state.orderForm.date_register
                      ? $store.state.orderForm.date_register
                      : ''
                  "
                  label="날짜"
                />
              </v-col>
              <v-col cols="12">
                <TextField v-model="$store.state.approvalMemo" label="비고" />
              </v-col>
            </v-row>
          </template>
          <template v-if="this.$route.name === 'claim'">
            <v-row>
              <v-col cols="4">
                <TextField
                  disabled
                  :value="
                    $store.state.saveKind === 'create'
                      ? `${claimForm.order ? claimForm.order.order : ''}`
                      : $store.state.saveKind === 'newTypeClaim'
                      ? newTypeClaimForm.id
                      : modfiyForm.order
                  "
                  label="Order"
                />
              </v-col>

              <v-col cols="4">
                <TextField
                  disabled
                  :value="
                    $store.state.saveKind === 'create'
                      ? `${claimForm.client ? claimForm.client.name : ''}`
                      : $store.state.saveKind === 'newTypeClaim'
                      ? newTypeClaimForm.clientName
                      : modfiyForm.clientName
                  "
                  label="업체명"
                />
              </v-col>
              <v-col cols="4">
                <TextField
                  disabled
                  :value="
                    $store.state.saveKind === 'create'
                      ? `${claimForm.currency_sign}${usingComma(
                          claimForm.price ? claimForm.price : '',
                        )}`
                      : $store.state.saveKind === 'newTypeClaim'
                      ? `${newTypeClaimForm.currency_sign}${newTypeClaimForm.price}`
                      : `${modfiyForm.currency_sign}${modfiyForm.price}`
                  "
                  item-text="label"
                  label="금액"
                />
              </v-col>
              <v-col cols="4">
                <TextField
                  disabled
                  :value="
                    typeStatus(
                      $store.state.saveKind === 'create'
                        ? claimForm.type
                        : $store.state.saveKind === 'newTypeClaim'
                        ? newTypeClaimForm.process_status
                        : modfiyForm.process_status,
                    )
                  "
                  item-text="label"
                  label="구분"
                />
              </v-col>
              <v-col cols="4">
                <TextField
                  disabled
                  :value="
                    $store.state.saveKind === 'create'
                      ? kindStatus(claimForm.kind ? claimForm.kind : '')
                      : $store.state.saveKind === 'newTypeClaim'
                      ? `${newTypeClaimForm.kind === '발생' ? '전가' : '발생'}`
                      : kindStatus(modfiyForm.kind)
                  "
                  item-text="label"
                  label="타입"
                />
              </v-col>
              <v-col cols="4">
                <TextField disabled :value="$store.state.Today" label="날짜" />
              </v-col>
              <v-col cols="12" v-if="$store.state.auth_grade <= 2">
                <TextField v-model="$store.state.approvalMemo" label="비고" />
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="$store.state.isDialog = false"
        >
          아니오
        </v-btn>
        <v-btn color="blue darken-1" text @click="callSave"> 예 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import TextField from "@/components/atoms/VTextField/TextField.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    TextField,
  },
  computed: {
    ...mapGetters({
      claimForm: "getClaimForm",
      modfiyForm: "getModifyClaim",
      newTypeClaimForm: "getNewTypeClaimForm",
    }),
    isConfirmDialog() {
      return this.$store.state.isDialog === true &&
        this.$store.state.isKind === "confirm_dialog"
        ? true
        : false;
    },
  },
  methods: {
    kindStatus(kind) {
      return kind === 1 ? "발생" : "전가";
    },
    typeStatus(type) {
      return type === 3 ? "매출" : type === 2 ? "매입" : "발행 예정";
    },
    async callSave() {
      if (this.$router.currentRoute.name === "claim") {
        if (this.$store.state.saveKind === "create")
          this.$store.dispatch(
            "TASK_CLAIM_SAVE",
            this.claimForm.kind === 1 ? "purchase" : "sales",
          );
        if (this.$store.state.saveKind === "modify")
          this.$store.dispatch("TASK_CLAIM_SAVE", "modifyClaim");
        if (this.$store.state.saveKind === "newTypeClaim") {
          this.$store.dispatch("TASK_CLAIM_SAVE", "newTypeClaim");
          this.$store.state.isClaimNewTypeDialog = false;
        }
      } else
        await this.$store.dispatch(
          "TASK_ORDER_SAVE",
          this.$store.state.saveKind,
        );
    },
  },
};
</script>
