var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px","value":_vm.isConfirmDialog},on:{"click:outside":function($event){_vm.$store.state.isDialog = false},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.$store.state.isDialog = false},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.callSave.apply(null, arguments)}]}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(" "+_vm._s(_vm.$store.state.dialogTitle)+" ")])]),_c('v-card-text',[_c('v-container',[(this.$route.name === 'order')?[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('TextField',{attrs:{"disabled":"","value":_vm.$store.state.orderForm.order
                    ? _vm.$store.state.orderForm.order
                    : '',"label":"Order"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('TextField',{attrs:{"disabled":"","value":_vm.$store.state.orderForm.client
                    ? _vm.$store.state.orderForm.client.name
                    : '',"label":"업체명"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('TextField',{attrs:{"disabled":"","value":_vm.$store.state.orderForm.date_register
                    ? _vm.$store.state.orderForm.date_register
                    : '',"label":"날짜"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('TextField',{attrs:{"label":"비고"},model:{value:(_vm.$store.state.approvalMemo),callback:function ($$v) {_vm.$set(_vm.$store.state, "approvalMemo", $$v)},expression:"$store.state.approvalMemo"}})],1)],1)]:_vm._e(),(this.$route.name === 'claim')?[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('TextField',{attrs:{"disabled":"","value":_vm.$store.state.saveKind === 'create'
                    ? ("" + (_vm.claimForm.order ? _vm.claimForm.order.order : ''))
                    : _vm.$store.state.saveKind === 'newTypeClaim'
                    ? _vm.newTypeClaimForm.id
                    : _vm.modfiyForm.order,"label":"Order"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('TextField',{attrs:{"disabled":"","value":_vm.$store.state.saveKind === 'create'
                    ? ("" + (_vm.claimForm.client ? _vm.claimForm.client.name : ''))
                    : _vm.$store.state.saveKind === 'newTypeClaim'
                    ? _vm.newTypeClaimForm.clientName
                    : _vm.modfiyForm.clientName,"label":"업체명"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('TextField',{attrs:{"disabled":"","value":_vm.$store.state.saveKind === 'create'
                    ? ("" + (_vm.claimForm.currency_sign) + (_vm.usingComma(
                        _vm.claimForm.price ? _vm.claimForm.price : ''
                      )))
                    : _vm.$store.state.saveKind === 'newTypeClaim'
                    ? ("" + (_vm.newTypeClaimForm.currency_sign) + (_vm.newTypeClaimForm.price))
                    : ("" + (_vm.modfiyForm.currency_sign) + (_vm.modfiyForm.price)),"item-text":"label","label":"금액"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('TextField',{attrs:{"disabled":"","value":_vm.typeStatus(
                    _vm.$store.state.saveKind === 'create'
                      ? _vm.claimForm.type
                      : _vm.$store.state.saveKind === 'newTypeClaim'
                      ? _vm.newTypeClaimForm.process_status
                      : _vm.modfiyForm.process_status
                  ),"item-text":"label","label":"구분"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('TextField',{attrs:{"disabled":"","value":_vm.$store.state.saveKind === 'create'
                    ? _vm.kindStatus(_vm.claimForm.kind ? _vm.claimForm.kind : '')
                    : _vm.$store.state.saveKind === 'newTypeClaim'
                    ? ("" + (_vm.newTypeClaimForm.kind === '발생' ? '전가' : '발생'))
                    : _vm.kindStatus(_vm.modfiyForm.kind),"item-text":"label","label":"타입"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('TextField',{attrs:{"disabled":"","value":_vm.$store.state.Today,"label":"날짜"}})],1),(_vm.$store.state.auth_grade <= 2)?_c('v-col',{attrs:{"cols":"12"}},[_c('TextField',{attrs:{"label":"비고"},model:{value:(_vm.$store.state.approvalMemo),callback:function ($$v) {_vm.$set(_vm.$store.state, "approvalMemo", $$v)},expression:"$store.state.approvalMemo"}})],1):_vm._e()],1)]:_vm._e()],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.$store.state.isDialog = false}}},[_vm._v(" 아니오 ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.callSave}},[_vm._v(" 예 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }