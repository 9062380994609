<template>
  <v-combobox
    :disabled="disabled"
    dense
    :refs="refs"
    :label="label"
    :value="value"
    :items="items"
    @input="$emit('input', $event)"
    @keyup.native.enter="$emit('keyup', $event)"
    :item-text="itemText"
    return-object
    :tabindex="tabindex"
  >
  </v-combobox>
</template>

<script>
export default {
  props: [
    "value",
    "label",
    "items",
    "refs",
    "disabled",
    "itemText",
    "tabindex",
  ],
};
</script>
