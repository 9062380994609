import { eventBus } from "@/main";
//text

// atoms
import GrayButton from "@/components/atoms/VButton/GrayButton";
import LabelSelect from "@/components/atoms/VSelect/LabelSelect";
import DenseLabelSelect from "@/components/atoms/VSelect/DenseLabelSelect";
import TextField from "@/components/atoms/VTextField/TextField";
import DenseTextField from "@/components/atoms/VTextField/DenseTextField";
// organisms
import LoadingSpinner from "@/components/organisms/LoadingSpinner/LoadingSpinner";
import SnackBar from "@/components/organisms/SnackBar/SnackBar";
// order
import ListForm from "@/components/organisms/FilterList/orderPageList.vue";

import ListHeaderForm from "./ListHeaderForm.vue";
import OrderForm from "./OrderForm";
import StyleForm from "./StyleForm";
import ColorForm from "./ColorForm";
import UnitpriceDialog from "./UnitpriceDialog";

import settingFormDialog from "@/components/organisms/VDialog/SettingFormDialog";
import CreateConfirmDialog from "@/components/organisms/VDialog/CreateConfirmDialog";

import { mapGetters } from "vuex";

export default {
  components: {
    GrayButton,
    DenseLabelSelect,
    LabelSelect,
    TextField,
    DenseTextField,
    LoadingSpinner,
    SnackBar,
    ListForm,
    printDialog: () => import("@/components/organisms/VDialog/PrintDialog.vue"),
    ListHeaderForm,
    OrderForm,
    StyleForm,
    ColorForm,
    UnitpriceDialog,

    settingFormDialog,
    CreateConfirmDialog,
  },
  data() {
    return {
      selected: [],
      SampleStatus: false,
      releaseList: [],
      manufactureList: [],
      claimList: [],
      apperanceList: [],
      orderBakNumFind: [],
    };
  },
  computed: {
    ...mapGetters({
      itemList: "getItemList",
      orderList: "getOrderList",
      userList: "getUserList",
      ctypeList: "getUseCtypeList",
      approvalDetailList: "getApprovalList",
      activeFilterData: "getActiveFilters",
    }),
    order: {
      get() {
        return this.$store.state.orderForm;
      },
      set(order) {
        this.$store.state.orderForm = order;
      },
    },

    clientList: {
      get() {
        return [...this.$store.state.clientList]
          .filter((a) => a.kind !== "inspection")
          .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
      },
    },

    heightLeftDatatable() {
      return `${this.windowHeight - 200}px`;
    },
    // 상단 클레임 버튼 Disable
    claimBtn_disable() {
      return this.order.order ? false : true;
    },
    // 단가수정요청상태일때 Disable
    unitPrice_disable() {
      return ["50"].includes(this.order.status);
    },
    // 샘플버튼 클릭시 Order번호에 Sam이나 Repeat 붙이는 함수
    formDateOrder() {
      const orderNum = this.order.SampleStatus
        ? String(this.order.order_front_year).slice(-2) +
          this.order.order_front_season +
          "-sam"
        : String(this.order.order_front_year).slice(-2) +
          this.order.order_front_season;
      this.$store.commit("setFormDateOrder", orderNum);
      return orderNum;
    },
    checkNullIndex() {
      let IndexArray = [];
      if (this.orderBakNumFind) {
        IndexArray = this.orderBakNumFind.filter((x) =>
          this.order.SampleStatus
            ? x.order_front === this.formDateOrder && x.kind === 2
            : this.order.RepeatStatus
            ? x.order_front === this.formDateOrder && x.kind === 3
            : x.status !== "99" &&
              x.order_front === this.formDateOrder &&
              x.kind !== 2 &&
              x.kind !== 3 &&
              x.kind !== 4,
        );
      }

      if (IndexArray.length === 0) return 1;
      const sortArr = IndexArray.sort((a, b) => a.order_back - b.order_back);

      for (let i = 0; i < sortArr.length; i++) {
        if (+sortArr[i].order_back !== i + 1) {
          return i + 1;
        }
      }

      return this.order.status ? +IndexArray.length : +IndexArray.length + 1;
    },
    //ORDER No에들어갈 데이터
    formOrder() {
      return this.order.RepeatStatus
        ? this.order.order + "-" + this.repeatNumber
        : !this.order._id
        ? this.formDateOrder +
          "-" +
          new Array(3 - this.checkNullIndex.toString().length + 1).join("0") +
          this.checkNullIndex
        : this.order.order;
    },
    repeatNumber() {
      const IndexArray = this.$store.state.orderPageOrigin.filter(
        (x) =>
          x.kind === 3 && x.order.split("-", 2).join("-") === this.order.order,
      );

      if (IndexArray.length === 0) return 1;
      const sortArr = IndexArray.sort(
        (a, b) => a.order_back_repeat - b.order_back_repeat,
      );

      for (let i = 0; i < sortArr.length; i++) {
        if (+sortArr[i].order_back_repeat !== i + 1) {
          return i + 1;
        }
      }
      return +IndexArray.length + 1;
    },
  },
  methods: {
    //api 데이터 호출
    getListWithoutOrder() {
      this.$store.dispatch("SET_CTYPELIST");
      this.$store.dispatch("SET_CLIENTLIST");
      this.$store.dispatch("SET_ITEMLIST");
      this.$store.dispatch("SET_USERLIST");
    },

    //order form 기초 데이터 셋팅
    async newOrder() {
      if (confirm("신규 오더를 작성하시겠습니까?")) {
        await this.listCall();
        this.$store.commit("setSnackBar", `오더 폼을 초기화 하였습니다.`);
      }
    },

    async listCall() {
      this.initOrder();
      await this.$store.dispatch("GET_COMPANY");
      // await this.$store.dispatch("NEW_ORDER_NO");
      await this.$store.dispatch("SET_USE_CLIENTLIST");
      await this.$store.dispatch("SET_USE_ITEMLIST");
      await this.$store.dispatch("SET_USE_CTYPELIST");
      // this.$store.dispatch("SET_ITEMLIST");
      // this.$store.dispatch("SET_CTYPELIST");
      // this.$store.dispatch("SET_USERLIST");
    },

    initOrder() {
      this.$store.commit("setOrderForm");
      this.$store.commit("setApprovalForm");
      // this.$state.dispatch("NEW_ORDER_NO");
      this.$store.dispatch("SET_DATA_FORM_BACKUP", this.order);
    },

    //오더클릭시 오더초기화
    setOrderForm(order) {
      this.order = JSON.parse(JSON.stringify(order));
      this.order.date_register = this.dateFormat(order.date_register);
      this.$store.dispatch("SET_DATA_FORM_BACKUP", this.order);
      this.$store.commit("setSnackBar", `Load Complete : ${this.order.order}`);
      //disabled
      this.order.liaison.name === this.$store.state.auth_name
        ? (this.disabledBtn = false)
        : (this.disabledBtn = true);
    },
    // 요청 클릭시 오더초기화
    async requestsetOrderForm(order) {
      order.company = this.$store.state.auth_company;
      if (typeof order == "undefined" || order == null || order == "") return;
      await this.$store.dispatch("SET_FORM", JSON.parse(JSON.stringify(order)));
      await this.$store.dispatch("SET_CLIENTLIST");
      await this.$store.dispatch("SET_ITEMLIST");
      await this.$store.dispatch("SET_CTYPELIST");

      this.$store.commit("setSnackBar", `Load Complete : ${order.order}`);
    },
    setOrderFormByApproval(value) {
      this.setOrder(value.order.order);

      this.dialog_approval_request = false;
      if (value.status === "50") {
        this.approval.Sales_unit_price = value.data.sales_unit_price;
        this.approval.memo = value.memo;
        // this.setDialogUnitPrice();
      }
    },
    // 클레임 버튼 누를시 동작 함수
    async pagemoveClaim(id, order) {
      this.claimList = {};
      await this.getclaimList(id);

      if (this.claimList.length >= 1) {
        this.$router.push({
          name: `claim`,
          params: { order: order },
        });
      } else {
        this.$store.commit("setSnackBar", `해당오더의 클레임이 없습니다.`);
      }
    },

    ///Repeat 오더 선택시 동작함수
    async RepeatOrder() {
      if (this.order.kind === 3) {
        this.$store.commit("setSnackBar", `이미 리피트 된 오더입니다.`);
        return;
      }
      if (confirm("해당 오더를 리피트 하시겠습니까?")) {
        this.$set(this.order, "RepeatStatus", true);
        this.$set(this.order, "SampleStatus", false);

        this.order.originOrder = this.order.order;
        await this.$store.dispatch("NEW_REPEAT_ORDER_NO");
        this.order.order = this.$store.state.newOrderNo.order;
        this.order.order_back_repeat =
          this.$store.state.newOrderNo.order_back_repeat;

        this.$store.commit("setApprovalForm");
        this.order.status = "";
        this.order.status_modify = true;
        this.order.kind = 3;
        delete this.order._id;
      }
    },

    totalDataCaculate(data) {
      this.sumTotalAmount = data.reduce((r, c) => r + c.sales_total_amount, 0);
      this.total_count = data.length ? data.length : 0;
    },
  },
  mounted() {},
  sockets: {
    async order(data) {
      if (this.$store.state.auth_company === data.company)
        await this.$store.dispatch("PARSE_ROUTE_API_DATA_CALL");
      if (!this.order._id) {
        this.$store.commit(
          "setSnackBar",
          `입력중이던 오더번호가 신규작성되어 오더번호를 변경합니다.`,
        );
        this.$store.dispatch("NEW_ORDER_NO");
      }
      if (this.order._id && this.order.order === data.order) {
        if (confirm("선택된 오더가 변경되어 입력중이던 폼이 변경됩니다.")) {
          await this.$store.dispatch(
            "SET_FORM",
            JSON.parse(JSON.stringify({ order: data.order })),
          );
        }
      }
    },
  },
  //승인요청 전체 선택
  async created() {
    if (!this.order._id) await this.listCall();
    this.$store.dispatch("SET_API_LOADING", false);
    if (this.order.order) this.requestsetOrderForm({ order: this.order.order });
  },

  beforeDestroy() {
    eventBus.$off("dialog_approval_on");
    // this.$store.commit("setOrderPageList");
  },
};
