<template>
  <div class="headerTitle">
    <v-card flat>
      <v-card-title class="px-0 mb-0 pb-0">
        {{
          order._id
            ? order.input_order
              ? order.input_order
              : order.order
            : $store.state.company.input_order_status
            ? order.order
            : $store.state.newOrderNo.order
        }}
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    formDateOrder: {},
    checkNullIndex: {},
  },
  components: {},
  data() {
    return {
      newOrderTooltip: "신규오더 작성 시 작성중인 내용은 사라집니다.",
      repeatTooltip:
        "해당 오더 번호로 리피트 오더번호가 매핑되며, 해당 오더의 데이터는 기본적으로 유지됩니다.",
      claimTooltip:
        "해당 오더의 클레임이 존재할 시, 해당 오더의 클레임으로 이동합니다.",
      deleteTooltip: "오더 삭제 시 삭제 된  오더는 되돌릴 수 없습니다.",
    };
  },
  computed: {
    ...mapGetters({
      order: "getOrderForm",
    }),
  },
  methods: {
    saveValidate() {
      if (!this.order.client._id) {
        this.$store.commit("setSnackBar", `구매처를 선택해주세요.`);
        return false;
      }
      if (!this.order.item._id) {
        this.$store.commit("setSnackBar", `아이템을 선택해주세요.`);
        return false;
      }
      if (!this.order.order_kind) {
        this.$store.commit("setSnackBar", `오더 유형을 선택해주세요.`);
        return false;
      }
      return true;
    },
    async confirmDialogOpen(type) {
      if (!this.saveValidate()) return;
      if (type === "create") {
        this.order.order = this.$store.state.newOrderNo.order;
        this.order.order_back = this.$store.state.newOrderNo.order_back;
        this.order.order_back_repeat =
          this.$store.state.newOrderNo.order_back_repeat;
      }

      this.order.order_front = this.formDateOrder;
      this.order.saveKind = type;
      const dialogTitleType = {
        create: this.$store.state.auth_grade >= 2 ? "승인" : "승인 요청",
        reject: "반려",
        deleteReject: "반려",
        delete: this.$store.state.auth_grade >= 2 ? "삭제" : "삭제 요청",
        modify: this.$store.state.auth_grade >= 2 ? "수정" : "수정 요청",
        userModify: "승인요청 수정",
      };

      this.$store.state.dialogTitle = dialogTitleType[type];
      (this.$store.state.saveKind = "order"),
        this.$store.commit("setDailogStatus", {
          status: true,
          kind: "confirm_dialog",
        });
    },
  },
};
</script>
