<template>
  <v-combobox
    :disabled="disabled"
    :dense="dense"
    :label="label"
    :value="value"
    :items="items"
    :hide-details="hideDetail"
    :solo="solo"
    :flat="flat"
    @input="$emit('input', $event)"
    @keyup.prevent="$emit('keyup', $event)"
    @change="$emit('change', $event)"
    :item-text="itemText"
    :tabindex="tabindex"
    :filter="searchClientFilter"
    return-object
  >
    <template v-slot:item="{ item }">
      <template
        v-if="
          items.filter((x) => x.name === item.name && x.status !== '99')
            .length >= 2
        "
      >
        <v-menu
          offset-x
          transition="slide-y-transition"
          max-width="400px"
          :nudge-width="400"
          nudge-top="10"
          open-on-hover
        >
          <template v-slot:activator="{ on }">
            <v-list-item-title v-on="on">
              <span class="red--text">*</span>{{ item.name }}
            </v-list-item-title>
          </template>

          <span>
            <v-list dense>
              <v-list-item-title style="padding: 0 6px 6px 6px">
                <span class="font-weight-bold">생산처 Item </span>
              </v-list-item-title>

              <v-list-item-group>
                <v-list-item
                  v-for="dupItem in items.filter(
                    (x) => x.name === item.name && x.status !== '99',
                  )"
                  :key="dupItem._id"
                  @click="dupleItemChange(dupItem)"
                >
                  <v-list-item-content>
                    {{ dupItem.production_item }}
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <slot />
          </span>
        </v-menu>
      </template>
      <template v-else>
        {{ item.name }}
      </template>
    </template>

    <template v-slot:prepend-inner>
      <v-btn
        icon
        x-small
        class="ma-0 pa-0"
        v-on:click.native.stop=""
        @click="$emit('click', value)"
        :disabled="(disabled || typeof value) === 'object' ? false : true"
      >
        <v-icon> mdi-pencil</v-icon>
      </v-btn>
    </template>
  </v-combobox>
</template>

<script>
export default {
  props: [
    "value",
    "label",
    "items",
    "disabled",
    "itemText",
    "tabindex",
    "change",
    "dense",
    "hideDetail",
    "solo",
    "flat",
    "filter",
  ],
  methods: {
    dupleItemChange(item) {
      this.$children[0].selectItem(item);
      this.$children[0].isActive = false;
    },
    searchClientFilter(item, queryText) {
      const searchTarget = `${item.name}`;

      return (
        searchTarget
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        searchTarget
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
  },
};
</script>
