<!-- 단가 수정 페이지  -->
<template>
  <v-dialog
    :value="dialog_unitPrice"
    max-width="500px"
    @click:outside="$store.state.isDialog = false"
    @keydown.esc="$store.state.isDialog = false"
  >
    <v-card>
      <v-card-title>{{ order.order }} 단가 수정</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <DenseTextField
              :disabled="unitPrice_disable"
              label="매출단가"
              v-model="approval.Sales_unit_price"
              type="number"
            ></DenseTextField>
            {{
              (approval.Sales_unit_price
                ? `수정 전 단가 : ${order.currency_sign}${order.sales_unit_price} -> 수정 단가 : ${order.currency_sign}${approval.Sales_unit_price}`
                : "") | makeComma
            }}
          </v-col>
          <v-col cols="12" class="pb-0 mb-0 pt-0 mt-0">
            <TextField
              :disabled="unitPrice_disable"
              v-model="approval.memo"
              label="비고"
            ></TextField>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="$store.state.isDialog = false"
        >
          close
        </v-btn>
        <!-- <v-btn
          v-if="$store.state.auth_grade >= 2 && order.status === '50'"
          color="red darken-1"
          text
          @click="
            $emit('setsaveData', 'modifyPriceReject', approval.Sales_unit_price)
          "
        >
          반려
        </v-btn>
        <v-btn
          v-if="$store.state.auth_grade >= 2 && order.status === '50'"
          color="green darken-1"
          text
          @click="
            $emit(
              'setsaveData',
              'modifyPriceConfirm',
              approval.Sales_unit_price,
            )
          "
        >
          요청 승인
        </v-btn> -->
        <v-btn
          v-if="
            $store.state.auth_grade >= 2 &&
            (order.status === '40' || order.status === '30')
          "
          color="blue darken-1"
          text
          @click="
            $emit(
              'setsaveData',
              'modifyPriceConfirm',
              approval.Sales_unit_price,
            )
          "
        >
          수정
        </v-btn>
        <v-btn
          v-if="
            $store.state.auth_grade <= 2 &&
            (order.status === '40' || order.status === '30')
          "
          color="blue darken-1"
          text
          @click="
            $emit(
              'setsaveData',
              'requestModifyPrice',
              approval.Sales_unit_price,
            )
          "
        >
          수정 요청
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DenseTextField from "@/components/atoms/VTextField/DenseTextField";
import TextField from "@/components/atoms/VTextField/TextField";
import { mapGetters } from "vuex";
export default {
  components: {
    DenseTextField,
    TextField,
  },
  computed: {
    ...mapGetters({
      approval: "getApproval",
    }),
    order: {
      get() {
        return this.$store.state.form;
      },
      set(order) {
        this.$store.state.form = order;
      },
    },
    approval: {
      get() {
        return this.$store.state.approval;
      },
      set(approval) {
        this.$store.state.approval = approval;
      },
    },
    dialog_unitPrice() {
      return this.$store.state.isDialog === true &&
        this.$store.state.isKind === "unitPrice"
        ? true
        : false;
    },
    unitPrice_disable() {
      return ["50"].includes(this.order.status);
    },
  },
};
</script>
